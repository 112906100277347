import React from 'react'

// Import the countdown and styles
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown'
import '@leenguyen/react-flip-clock-countdown/dist/index.css'

// Import the logo, image, and CSS file
import Logo from '../src/assets/logo.png' // Replace with the actual path to the logo image
import './styles.css'
import VideoBg from '../src/assets/VideoBg.mp4'

const App = () => {
  // Set the target date for the countdown (e.g., 1st November 2024)
  const targetDate = new Date('2024-11-03T00:00:00').getTime()

  return (
    <section className="page">
      <div className="overlay"></div>

      {/* Commenting out the video section, as per the request */}
      <video src={VideoBg} autoPlay loop muted></video>

      {/* Logo at the top */}
      <div className="logo">
        <img src={Logo} alt="Logo" className="logo-image" />
      </div>

      <div className="page__content">
        <h1>Launching Soon</h1>

        <FlipClockCountdown
          to={targetDate} // Countdown target date
          className="flip-clock"
          Labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
          duration={0.5}
        />
      </div>
    </section>
  )
}

export default App
